<template>
  <div :style="{height: winHeight + 'px'}">
    <div class="iframe-container">
      <iframe src="https://api.myonewow.com/ziomyun/tprank.php" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      winHeight: 200,
      hasPermission: true,
    };
  },
  mounted() {
    const wh = document.body.clientHeight;
    this.winHeight = wh - 150;
  },
};
</script>

<style scoped>
.iframe-container {
  position: relative;
  height: 100%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none;
}
</style>
